$( document ).ready(function() {    
    let FinalOTP;
    
    // API Call Requests
    const PostEnable2FA = (code, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/accounts/2fa/enable?code=' + code,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostDisable2FA = (code, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/accounts/2fa/disable?code=' + code,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    
    const Get2FACode = (user_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: '/en/api/accounts/2fa/request_code?user_id=' + user_id,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }    
    const Post2FAVerify = (user_id, code, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/accounts/2fa/verify?user_id=' + user_id + '&code=' + code,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }   
    const Post2FALogin = (user_id, code, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/accounts/2fa/login?user_id=' + user_id + '&code=' + code,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback)
        .fail((err) => {
            if (err.status === 400) {
                showToast(`
                    <h4>2 Factor Auth</h4>
                    <p>Invalid OTP!</p>
                `);
            } else {
                showToast(`
                    <h4>2 Factor Auth</h4>
                    <p>An unexpected error occurred. Please try again.</p>
                `);
            }
        });
    }  

    

    // Function to update the hidden input field
    function updateHiddenOTP() {
        const otp = $('.otp-container input')
            .map(function () {
                return $(this).val();
            })
            .get()
            .join(''); 

        $('input[name="2faOTP"]').val(otp);
        FinalOTP =  otp;
    }

    // Handle OTP paste
    $(document).on('paste', '.otp-container', function (e) {
        const pastedText = e.originalEvent.clipboardData.getData('text');
        if (pastedText.length === 6 && /^[0-9]+$/.test(pastedText)) {
            $('.otp-container input').each(function (index) {
                $(this).val(pastedText[index]);
            });
            $('#otp6').focus(); // Move focus to the last input

            // Update the hidden OTP field
            updateHiddenOTP();
        }
    });

    // Handle OTP input and backspace navigation
    $(document).on('input', '.otp-container input', function (e) {
        const currentInput = $(this);
        const value = currentInput.val();
        const currentInputIndex = currentInput.index();
        const inputs = $('.otp-container input');

        // Handle forward navigation for single character input
        if (value && currentInputIndex < inputs.length - 1) {
            inputs.eq(currentInputIndex + 1).focus();
        }

        // Update the hidden OTP field
        updateHiddenOTP();
    });

    $(document).on('keydown', '.otp-container input', function (e) {
        const key = e.key;
        const currentInput = $(this);
        const currentInputIndex = currentInput.index();
        const inputs = $('.otp-container input');

        if (key === 'Backspace') {
            if (currentInput.val() === '' && currentInputIndex > 0) {
                const prevInput = inputs.eq(currentInputIndex - 1);
                prevInput.focus().val(''); 
            }
        }

        setTimeout(updateHiddenOTP, 0); 
    });

    setTimeout(function(){
       $('.twoFa_container button').removeClass('disabled');
    }, 120000);  


    function startCountdown(duration, display) {
        var timer = duration, minutes, seconds;

        var countdownInterval = setInterval(function() {
            minutes = Math.floor(timer / 60);
            seconds = timer % 60;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            display.text(minutes + ":" + seconds);
            timer--;

            if (timer < 0) {
                clearInterval(countdownInterval);
                $('.hide-later').hide();                
                $('.js-request-otp').removeClass('disabled');
            }
        }, 1000); 
    }

    const countdownDuration = 119;
    const display = $('.timer');
    startCountdown(countdownDuration, display);   



    $('.js-enable-auth').on('click', function() {
        $('.auth-method-chooser').addClass('open');
        $('.auth-method-chooser .js-start-auth').addClass('enable-auth');
        $('body').addClass('locked')
    })
    $('.js-disable-auth').on('click', function() {
        $('.auth-method-chooser').addClass('open');
        $('.auth-method-chooser .js-start-auth').addClass('disable-auth');
        $('body').addClass('locked')
    })
    $('.auth-method-chooser .close--popup').on('click', function() {
        $('.auth-method-chooser').removeClass('open');
        $('body').removeClass('locked')
    })

    $('.js-start-auth').on('click', function() {
        const user_id = $(this).data('user-id');
        const urlPath = $(this).hasClass('enable-auth') ? 'enable' : 'disable';
        $(this).addClass('loading');
        $('.loading-state').addClass('loading');
        $('.auth-method-chooser svg.close--popup').hide();
        Get2FACode(user_id, function(){
            setTimeout(function(){
                window.location.href = `/en/accounts/2fa/${urlPath}`;
            }, 600)
        })
    })
    
    $('.js-verify-otp').on('click', function() {
        const user_id = $(this).hasClass('login_2fa') ? $(this).data('user-id-login') : $(this).data('user-id');
        const code = FinalOTP;
        const status = $(this).hasClass('auth-enabled') ? 'enabled' : 'disabled';            
                 
        const navWin = (data) => {  
            console.log('data', data)
            showToast(`
                <h4>2 Factor Auth</h4>
                <p>Settings ${status === 'enabled' ? 'Disabled' : 'Enabled'}!</p>
            `);                             
            setTimeout(function(){
                window.location.href = "/en/accounts/profile";
            }, 1000);
        }            
        
        if ($(this).hasClass('login_2fa')){
            Post2FALogin(user_id, code, function(){
                showToast(`
                    <h4>2 Factor Auth</h4>
                    <p>Login Successfull!</p>
                  `);
                                            
                setTimeout(function(){
                    window.location.href = "/en/accounts/";
                }, 1500); 
            })
        } else{   
            Post2FAVerify(user_id, code, function(data){
                if(data){
                    status === 'disabled' ? PostEnable2FA(code, navWin()) : PostDisable2FA(code, navWin());
                }else{
                    showToast(`
                        <h4>2 Factor Auth</h4>
                        <p>Invalid OTP!</p>
                    `);
                }
            })
        }       
        
    })

    $('.js-request-otp').on('click', function() {
        const user_id = $(this).hasClass('login_2fa') ? $(this).data('user-id-login') : $(this).data('user-id');
        $(this).addClass('disabled');

        Get2FACode(user_id, function(){
            $('.hide-later').show();  
            startCountdown(countdownDuration, display); 
            showToast(`
                <h4>2 Factor Auth</h4>
                <p>New OTP Generated.</p>
            `);
        })
    }) 
})